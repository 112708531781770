import { MessageDiv, StyledArticle } from '../../styles';

import { Layout } from '../../components/Layout';
import React from 'react';
import { StyledH2 } from '../../styles/common';

import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Zmiana hasła'
		description='Zmiana hasła.'
	/>);

const JakOdzyskacHasloPage = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				Zmiana hasła
			</StyledH2>
			<MessageDiv>
				W przypadku utraty hasła lub jego ujawnienia osobom trzecim, należy
				zwrócić się do przełożonego z prośbą o zmianę hasła do portalu
				pracownika.
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default JakOdzyskacHasloPage;
